
import './App.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import Web3 from 'web3';
import axios from 'axios';
import React, { Component } from 'react';
import Nftpuller from './n2dpuller/nftpuller';
const ABI = [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_mintAmount",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_state",
				"type": "bool"
			}
		],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "_data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseExtension",
				"type": "string"
			}
		],
		"name": "setBaseExtension",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_newmaxMintAmount",
				"type": "uint256"
			}
		],
		"name": "setmaxMintAmount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseExtension",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maxMintAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maxSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_owner",
				"type": "address"
			}
		],
		"name": "walletOfOwner",
		"outputs": [
			{
				"internalType": "uint256[]",
				"name": "",
				"type": "uint256[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
]
const VAULTABI = [
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "tokenIds",
				"type": "uint256[]"
			}
		],
		"name": "claim",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "uint256[]",
				"name": "tokenIds",
				"type": "uint256[]"
			}
		],
		"name": "claimForAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "contract Collection",
				"name": "_nft",
				"type": "address"
			},
			{
				"internalType": "contract CRUXRewards",
				"name": "_token",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Claimed",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "NFTStaked",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "NFTUnstaked",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "tokenIds",
				"type": "uint256[]"
			}
		],
		"name": "stake",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "tokenIds",
				"type": "uint256[]"
			}
		],
		"name": "unstake",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "tokenIds",
				"type": "uint256[]"
			}
		],
		"name": "earningInfo",
		"outputs": [
			{
				"internalType": "uint256[2]",
				"name": "info",
				"type": "uint256[2]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "",
				"type": "bytes"
			}
		],
		"name": "onERC721Received",
		"outputs": [
			{
				"internalType": "bytes4",
				"name": "",
				"type": "bytes4"
			}
		],
		"stateMutability": "pure",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "tokensOfOwner",
		"outputs": [
			{
				"internalType": "uint256[]",
				"name": "ownerTokens",
				"type": "uint256[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalStaked",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "vault",
		"outputs": [
			{
				"internalType": "uint24",
				"name": "tokenId",
				"type": "uint24"
			},
			{
				"internalType": "uint48",
				"name": "timestamp",
				"type": "uint48"
			},
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
]


var account = null;
var contract = null;
var vaultcontract = null;

const data = [
  {
    id: "1",
    img: "https://ipfs.io/ipfs/bafybeidjqukb567zqcis2dhbkfzzf54qpicevtjnqxlpfznti3sttp44qi/143.png",
    title: "DeadPixel #1",
    buttontext: "Buy Now"
  },
  {
    id: "2",
    img: "https://ipfs.io/ipfs/bafybeidjqukb567zqcis2dhbkfzzf54qpicevtjnqxlpfznti3sttp44qi/2.png",
    title: "DeadPixel #2",
    buttontext: "Buy Now"
  },
  {
    id: "3",
    img: "https://ipfs.io/ipfs/bafybeidjqukb567zqcis2dhbkfzzf54qpicevtjnqxlpfznti3sttp44qi/3.png",
    title: "DeadPixel #3",
    buttontext: "Buy Now"
  },
  {
    id: "4",
    img: "https://ipfs.io/ipfs/bafybeidjqukb567zqcis2dhbkfzzf54qpicevtjnqxlpfznti3sttp44qi/4.png",
    title: "DeadPixel #4",
    buttontext: "Buy Now"
  },
  {
    id: "5",
    img: "https://ipfs.io/ipfs/bafybeidjqukb567zqcis2dhbkfzzf54qpicevtjnqxlpfznti3sttp44qi/5.png",
    title: "DeadPixel #5",
    buttontext: "Buy Now"
  },
  {
    id: "6",
    img: "https://ipfs.io/ipfs/bafybeidjqukb567zqcis2dhbkfzzf54qpicevtjnqxlpfznti3sttp44qi/6.png",
    title: "DeadPixel #6",
    buttontext: "Buy Now"
  },
  {
    id: "7",
    img: "https://ipfs.io/ipfs/bafybeidjqukb567zqcis2dhbkfzzf54qpicevtjnqxlpfznti3sttp44qi/7.png",
    title: "DeadPixel #7",
    buttontext: "Buy Now"
  },
  {
    id: "8",
    img: "https://ipfs.io/ipfs/bafybeidjqukb567zqcis2dhbkfzzf54qpicevtjnqxlpfznti3sttp44qi/8.png",
    title: "DeadPixel #8",
    buttontext: "Buy Now"
  },
  {
    id: "9",
    img: "https://ipfs.io/ipfs/bafybeidjqukb567zqcis2dhbkfzzf54qpicevtjnqxlpfznti3sttp44qi/9.png",
    title: "DeadPixel #9",
    buttontext: "Buy Now"
  },
  {
    id: "10",
    img: "https://ipfs.io/ipfs/bafybeidjqukb567zqcis2dhbkfzzf54qpicevtjnqxlpfznti3sttp44qi/10.png",
    title: "DeadPixel #10",
    buttontext: "Buy Now"
  },
  {
    id: "11",
    img: "https://ipfs.io/ipfs/bafybeidjqukb567zqcis2dhbkfzzf54qpicevtjnqxlpfznti3sttp44qi/11.png",
    title: "DeadPixel #11",
    buttontext: "Buy Now"
  },

]


const ADDRESS = "0x539B3E00334C104cD9aDF63b65f41e4baB169A1E";
const STAKINGCONTRACT = "0x93a9b6C6a8BAB02eaf8A64d42AB6FbF3caA3B3dd"
const apikey = "2XF51JUFS3U6U3VQIKSY7HKWB2PCUT19S3";
const endpoint = "https://api.polygonscan.com/api"
const openseaapi = "https://testnets-api.opensea.io/api/v1/assets";
const nftpng = "https://ipfs.io/ipfs/bafybeidjqukb567zqcis2dhbkfzzf54qpicevtjnqxlpfznti3sttp44qi/";









class App extends Component {
	constructor() {
		super();
		this.state = {
			balance: [],
			data: [],
		};
	}

	async componentDidMount() {
		const etherscan = await axios.get(endpoint + `?module=stats&action=tokensupply&contractaddress=${ADDRESS}&apikey=${apikey}`);

		let { result } = etherscan.data;
		this.setState({
		 balance:result,
		 data: data
		})
	
		await axios.get((endpoint + `?module=stats&action=tokensupply&contractaddress=${ADDRESS}&apikey=${apikey}`))
		.then(outputa => {
            this.setState({
                balance:outputa.data
            })
            console.log(outputa.data)
        })

  }
  render() {
	const {balance} = this.state;
	const {nftdata} = this.state;
	
	
	async function connectwallet() { 
		if (window.ethereum) { 
		var web3 = new Web3(window.ethereum); 
		await window.ethereum.send('eth_requestAccounts'); 
		var accounts = await web3.eth.getAccounts(); 
		account = accounts[0]; 
		document.getElementById('wallet-address').textContent = account; 
		contract = new web3.eth.Contract(ABI, ADDRESS);
		vaultcontract = new web3.eth.Contract(VAULTABI, STAKINGCONTRACT);
		}
  }
  async function mint() {
	var _mintAmount = Number(document.querySelector("[name=amount]").value); 
	var mintRate = Number(await contract.methods.cost().call()); 
	var totalAmount = mintRate * _mintAmount; 
  contract.methods.mint(account, _mintAmount).send({ from: account, value: String(totalAmount) }); 
} 
async function stakeit() {
	var tokenids = Number(document.querySelector("[name=stkid]").value);
	vaultcontract.methods.stake([tokenids]).send({from: account});
}
async function unstakeit() {
	var tokenids = Number(document.querySelector("[name=stkid]").value);
	vaultcontract.methods.unstake([tokenids]).send({from: account});
}
async function claimit() {
	var tokenids = Number(document.querySelector("[name=claimid]").value);
	vaultcontract.methods.claim([tokenids]).send({from: account});
}

async function verify() {
	var getbalance = Number(await vaultcontract.methods.balanceOf(account).call());
	document.getElementById('stakedbalance').textContent = getbalance; 
}
async function enable() {
	contract.methods.setApprovalForAll(STAKINGCONTRACT, true).send({from: account});
  }
  async function rewardinfo() {
	var tokenid = Number(document.querySelector("[name=satkid]").value);
	var rawearn = await vaultcontract.methods.earningInfo(account, ([tokenid])).call();
	var earned =  String(rawearn).split(",")[0];
	var earnedrwd = Web3.utils.fromWei(earned);
	var rewards = Number(earnedrwd).toFixed(2);
	document.getElementById('earned').textContent = rewards;
  
  }

  return (
    <div className="App">
      
 <div className='container'>
 <nav class="navbar background">
          
            <ul class="nav-list">
                <li><a href="https://inversolabs.com">inversolabs</a></li>
                <li><a href="#file">deadpixel</a></li>
                <li><a href="https://magiceden.io/marketplace/crux">cruxinverso</a></li>
                <li><a href="https://magiceden.io/marketplace/digitaltwin">digitaltwin</a></li>
            </ul>
         
            <div class="rightNav">
      
                <input type="text" name="search" id="search" />
                <button class="btn btn-sm">Search</button>
            </div>
        </nav>
		<div classname='container'>
	<img style={{borderRadius:"360px",marginBottom:"5px",marginTop:"20px",height:"360px",width:"360px"}}SRC="11.gif"></img>
	</div>
  <div classname='container'>
    <h1 style={{color:"#FFFFFF", textShadow:"3px 3px 10px #000000"}}>DeadPixel Social Club</h1>
    <h4 style={{color:"#FFFFFF"}}>142 Unique NFTs</h4>
    <h2 style={{color:"#FFFFFF"}}>InversoLabs x Polygon Network</h2>
  </div>
<div className='row'>
<div className="gradient2 border d-flex align-items-center justify-content-center">
  <form class="gradient2 col-lg-5 mt-5 mb-5" style={{borderRadius:"25px",boxShadow:"1px 1px 15px #000000"}}>
    <h4 style={{color:"#FFFFFF", textShadow:"3px 3px 10px #000000"}}>DEAD PIXEL MINT PORTAL</h4>
    <h5 style={{color:"#FFFFFF"}}>Please connect your wallet</h5>
    <Button onClick={connectwallet} style={{marginBottom:"5px",color:"#FFFFFF"}}>Connect Wallet</Button>
	
    <div class="card gradientX" id='wallet-address' style={{marginTop:"3px",boxShadow:"1px 1px 4px #000000"}}>
      <label for="floatingInput">Wallet Not Connected</label>
      </div>
      <div class="card" style={{marginTop:"3px",boxShadow:"1px 1px 4px #000000"}}>
      <input type="number" name="amount" defaultValue="1" min="1" max="5"/>
      <label >Please select the amount of NFTs to mint.</label>
      <Button onClick={mint}>Buy/Mint!</Button>
      </div>
      
    <label style={{color:"#FFFFFF"}}>Price 1 MATIC each mint.</label>
	<h5 style={{color:"white", textShadow:"1px 1px 3px #000000"}}> Tokens Minted so far= {balance.result}/142</h5>
  </form>
  </div>
  <div className="gradient2 border d-flex align-items-center justify-content-center">
  <form class="gradient2 col-lg-3 mt-5 mb-5" style={{borderRadius:"25px",boxShadow:"1px 1px 15px #000000", marginRight:"30px"}}>
    <h4 style={{color:"#FFFFFF",textShadow:"1px 1px 15px #000000"}}>NFT STAKING VAULT</h4>
    <h5 style={{color:"#FFFFFF"}}>Please connect your wallet</h5>
	<h10 style={{color:"#FFFFFF"}}>click enable and approve</h10><br></br>
		<Button onClick={enable}>Enable Staking</Button>
      <div class="card" style={{marginBottom:"3px",marginTop:"3px",boxShadow:"1px 1px 4px #000000"}}>
      <input type="number" name="stkid"/>
      <label >Input NFT ID</label>
      <Button onClick={stakeit}>STAKE</Button>
	  <Button onClick={unstakeit}>UNSTAKE</Button>
	  
      </div>
	  <label></label>
	  <h10 style={{color:"#FFFFFF"}}>Stake to earn CRUX tokens</h10>
	  </form>
	  <form class="gradient2 col-lg-3 mt-5 mb-5" style={{borderRadius:"25px",boxShadow:"1px 1px 15px #000000", marginRight:"25px"}}>
    <h4 style={{color:"#FFFFFF",textShadow:"1px 1px 15px #000000"}}>NFT VAULT OPTIONS</h4>
	<h5 style={{color:"#FFFFFF"}}>Verify Amount Staked</h5>
	<Button onClick={verify}>Verify</Button>
	<div class="card" id='stakedbalance' style={{marginBottom:"3px",marginTop:"3px",boxShadow:"1px 1px 4px #000000"}}>
      <label for="floatingInput">NFT Balance</label>
      </div>
      <div class="card" style={{marginBottom:"3px",marginTop:"3px",boxShadow:"1px 1px 4px #000000"}}>
      <input type="number" name="claimid"/>
      <label >Input NFT ID</label>
	  <Button onClick={claimit}>Claim Rewards</Button>
      </div>
	  
	  <label></label>
	  <h6 style={{color:"#FFFFFF"}}>Claim CRUX Token</h6>
  </form>

  </div>
  <div classname='container'>
    <h1 style={{color:"#FFFFFF", textShadow:"3px 3px 10px #000000"}}>DeadPixel Social Club</h1>
    <h4 style={{color:"#FFFFFF"}}></h4>
    <h2 style={{color:"#FFFFFF"}}>View the Collection & Listings On OpenSea</h2>
  </div>
  <div>
	
  </div>
  
  <div className="row items mt-3">
 
	
	  return (

		<Nftpuller/>
		
		);
		
</div>
  </div>
	</div>
	</div>
	

  			);
	};
}


export default App;